<template>
  <div class="box">
    <div class="mb-4 relative">

      <!--  -->
      <div class="accordion-item col-span-12 text-xs">
        <div id="faq-accordion-content" class="border-b border-gray-200 dark:border-dark-5 accordion-header flex justify-between col-span-2">
          <button
            class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#faq-accordion-collapse-1"
            aria-expanded="true"
            aria-controls="faq-accordion-collapse-1"
          >
            <div class="flex flex-col sm:flex-row items-center p-4">
              <h4 class="mr-auto capitalize text-base font-medium font-bold">Gráfica Auditoria</h4>
            </div>
          </button>
        </div>
        <div id="faq-accordion-collapse" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content" data-bs-parent="#faq-accordion">
          <div class="accordion-body text-gray-700 m-4 dark:text-gray-600 leading-relaxed">

            <!-- Filtros -->
            <section class="w-full pb-6">
              <div class="grid grid-cols-7 gap-x-6 gap-y-2">
                <div class="col-span-7 sm:col-span-2">
                  <label for="fechaReportada" class="block text-sm font-medium text-gray-700">Año:</label>
                  <select
                    v-model="filters.year"
                    class="form-select form-select-sm mt-1"
                    name="year"
                    id="year"
                  >
                    <option v-for="(row,k) in optionsFilters.year" :key="k" :value="row">{{ row }}</option>
                  </select>
                </div>
                <div class="col-span-7 sm:col-span-1 flex items-end justify-start">
                  <Button
                    label="Buscar"
                    icon="pi pi-search"
                    class="p-button-xs"
                    @click="handleSearch"
                  />
                </div>
              </div>
            </section>

            <div v-show="cargando" style="width:100%; height: 300px" class="rounded-md icono-carga flex justify-center items-center relative">
              <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 300px"/>
            </div>

            <!--  -->
            <section class="w-full pb-4" v-show="!cargando">
              <div class="grid grid-cols-12 gap-x-6 gap-y-2">
                <div class="col-span-12 sm:col-span-4 md:col-span-4 lg:col-span-3 xl:col-span-2 xxl:col-span-2">
                  <p class="block text-sm font-medium text-gray-700">Listado de Proveedores</p>
                  <div class="flex w-full p-4">
                    <Checkbox
                      :id="`proveedor_all`"
                      class=""
                      v-model="checked"
                      :binary="true"
                      @change="selectAll"
                    />
                    <label class="pl-4 uppercase" :for="`proveedor_all`">Todos</label>
                  </div>
                  <div class="flex flex-col py-4 border-t" style="height: 450px; overflow-y: auto">
                    <div v-for="(prov, key) in listProveedores" :key="key" class="flex w-full mb-4">
                      <Checkbox
                        :id="`proveedor_${prov.nit}`"
                        class="ml-4"
                        name="proveedor"
                        :value="prov"
                        v-model="filters.proveedores"
                      />
                      <label class="pl-4" :for="`proveedor_${prov.nit}`">{{ prov.nombreCorto }}</label>
                    </div>
                  </div>
                </div>

                <div class="col-span-12 sm:col-span-8 md:col-span-8 lg:col-span-9 xl:col-span-10 xxl:col-span-10">
                  <div class="flex flex-col">
                    <p class="block text-sm font-medium text-gray-700 pb-4">Gráfica Auditoria</p>
                    <div
                      id="grafica"
                      ref="elGrafica"
                      style="height:500px;"
                      class="w-full"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive, ref, watch } from 'vue'
import {
  apiAuditoriaGrafica,
  apiAuditoriaGraficaTotales,
  apiFiltersGraficaAuditoria,
  apiGetProveedores
} from './services'
import { helper } from '../../../../../../../utils/helper'
import currency from 'currency.js'

export default {
  name: 'graficaAuditoria',
  components: {},
  props: {
    typeCompany: {
      type: Number,
      default: 1
    }
  },
  setup (props) {
    const filters = reactive({
      year: new Date().getFullYear(),
      proveedores: [],
      typeCompany: props.typeCompany
    })
    const checked = ref(true)
    const cargando = ref(false)
    const optionsFilters = ref([])
    const listProveedores = ref([])
    const elGrafica = ref(null)
    const chartData = ref([])
    const chartDataProveedor = ref([])
    let chart = reactive({})

    /**/
    const selectAll = () => {
      if (checked.value) {
        filters.proveedores = listProveedores.value
      } else {
        filters.proveedores = []
      }
    }

    /**/
    watch(() => filters.proveedores, (newValue) => {
      checked.value = newValue.length === listProveedores.value.length
    })

    /* Obtener los proveedores */
    const fetchProveedores = () => {
      return apiGetProveedores(filters).then(({ status, data: response }) => {
        listProveedores.value = response
        cargando.value = false
      }).catch(err => {
        console.error(err.message)
        cargando.value = false
      })
    }

    /* Obtener los filtros */
    const fetchFilters = async () => {
      return apiFiltersGraficaAuditoria(filters).then(async ({ status, data: response }) => {
        optionsFilters.value = response
        filters.proveedores = listProveedores.value
        await fetchDataTotales()
        await fetchData()
      }).catch(err => {
        console.error(err.message)
      })
    }

    /**/
    const fetchDataTotales = async () => {
      return apiAuditoriaGraficaTotales(filters).then(({ data }) => {
        chartData.value = data.totales
        cargando.value = false
        reloadChart()
      }).catch((err) => {
        console.error(err.message)
        cargando.value = false
      })
    }

    const fetchData = async () => {
      return apiAuditoriaGrafica(filters).then(({ data }) => {
        chartDataProveedor.value = data.data
      }).catch((err) => {
        console.error(err.message)
      })
    }

    /**/
    const getChartDataTotal = (key) => {
      const arrayMonths = ['', '', '', '', '', '', '', '', '', '', '', '']
      chartData.value.map(item => {
        arrayMonths[item.mes - 1] = item[key]
      })
      return arrayMonths
    }

    /**/
    const getChartDataProveedores = (infoData) => {
      const month = infoData.dataIndex + 1

      return chartDataProveedor.value.filter((a) => {
        return a.year === filters.year && a.mes === month
      })
    }

    /* Realizar busqueda */
    const handleSearch = () => {
      cargando.value = true
      fetchDataTotales()
      fetchData()
      fetchProveedores()
    }

    /**/
    const initChart = () => {
      chart = echarts.init(elGrafica.value, 'light')
      const option = {
        grid: {
          left: 20,
          right: 20,
          bottom: 15,
          top: 50,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
            'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
          ]
        },
        dataZoom: [
          {
            type: 'slider',
            show: false,
            xAxisIndex: [0],
            filterMode: 'none',
            startValue: 'Enero',
            endValue: 'Marzo',
            zoomLock: false
          },
          {
            type: 'inside',
            startValue: 'Enero',
            endValue: 'Marzo',
            zoomLock: false
          }
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 10,
            showMinLabel: 10,
            formatter: (params) => {
              if (window.innerWidth > 1024) {
                return helper.formatCurrency(params, 0)
              } else {
                return `${currency(params / 1000000, { precision: 0, separator: '.' }).format()}M`
              }
            }
          }
        },
        tooltip: {
          axisPointer: {
            type: 'cross'
          },
          trigger: 'item',
          position: (pos) => {
            if (window.innerWidth > 1024) {
              return pos[0]
            } else {
              return ['5%', '0']
            }
          },
          formatter (a) {
            const testing = getChartDataProveedores(a)
            let htmlString = `
              <div class="flex justify-center text-xs font-bold uppercase">
                <span>${a.seriesName}</span>
              </div>
              <div class="flex justify-between pb-2 border-b text-xs font-semibold uppercase" style="width: 250px">
                <span>${a.name}</span>
                <span>${helper.formatCurrency(a.value, 0)}</span>
              </div>
            `
            for (let index = 0; index < testing.length; index++) {
              if (a.seriesName === 'Facturado') {
                htmlString += `
                <div class="flex justify-between pt-2 text-xs" style="width: 250px">
                  <span>${testing[index].nombreCorto}</span>
                  <span>${helper.formatCurrency(testing[index].valorFactura, 0)}</span>
                </div>`
              } else if (a.seriesName === 'Glosado') {
                htmlString += `
                <div class="flex justify-between pt-2 text-xs" style="width: 250px">
                  <span>${testing[index].nombreCorto}</span>
                  <span>${helper.formatCurrency(testing[index].valorGlosas, 0)}</span>
                </div>`
              } else {
                htmlString += `
                <div class="flex justify-between pt-2 text-xs" style="width: 250px">
                  <span>${testing[index].nombreCorto}</span>
                  <span>${helper.formatCurrency(testing[index].valorAceptado, 0)}</span>
                </div>`
              }
            }
            return htmlString
          }
        },
        legend: {
          data: ['Facturado', 'Glosado', 'Aceptado']
        }
      }

      chart.setOption(option)
      loadEvents()
    }

    const reloadChart = () => {
      const option = {
        xAxis: {
          type: 'category',
          data: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
            'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
          ]
        },
        series: [
          {
            name: 'Facturado',
            data: getChartDataTotal('totalValorFacturado'),
            type: 'line',
            symbolSize: 15,
            color: '#04BF45'
          },
          {
            name: 'Glosado',
            data: getChartDataTotal('totalValorGlosado'),
            type: 'line',
            symbolSize: 15,
            color: '#c31776'
          },
          {
            name: 'Aceptado',
            data: getChartDataTotal('totalValorAceptado'),
            type: 'line',
            symbolSize: 15,
            color: '#F29F05'
          }
        ]
      }

      chart.setOption(option)
    }

    /**/
    const loadEvents = () => {
      window.addEventListener('resize', evt => {
        chart.resize()
      })
    }

    onMounted(async () => {
      initChart()
      await fetchProveedores()
      await fetchFilters()
    })

    return {
      listProveedores,
      elGrafica,
      filters,
      optionsFilters,
      handleSearch,
      checked,
      selectAll,
      cargando
    }
  }
}
</script>

<style>
.title-tool{
  display:flex;width:275px;text-transform: uppercase;border-bottom:1px solid;padding-bottom: 3px
}
.title-tool p{
  white-space: pre-line;
}
</style>
