import http from '../../../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD_UTESPECIALIZADA}`

const apiGetProveedores = (query) => http.get(`${api}/dashboard/proveedores`, { params: { ...query } })
const apiFiltersGraficaAuditoria = (query) => http.get(`${api}/dashboard/filters-grafica-auditoria`, { params: { ...query } })
const apiAuditoriaGrafica = (query) => http.get(`${api}/dashboard/auditoria-grafica`, { params: { ...query } })
const apiAuditoriaGraficaTotales = (query) => http.get(`${api}/dashboard/auditoria-grafica-totales`, { params: { ...query } })

export {
  apiGetProveedores,
  apiFiltersGraficaAuditoria,
  apiAuditoriaGrafica,
  apiAuditoriaGraficaTotales
}
